(()=>{
  let win           = window;
  let $win          = $(window);
  let $body         = $('body');
  let eventListener = {
    'resize'           : {},
    'break'            : {},
    'firstBreakBefore' : {},
    'firstBreakAfter'  : {},
    'scroll'           : {},
    'modal'            : {},
    'toggleFocus'      : {}
  };
  let state = {
    displaySize  : '',
    scrollTop    : 0,
    nativeScroll : false,
    queryString  : null
  };
  marugoto.eventListener = eventListener;
  marugoto.state         = state;
  marugoto.global        = {};

  // let a = ()=>true;
  //
  // console.log(a());
  // ###########################################################################
  //
  // イベントオブザーバー
  //
  // ###########################################################################
  // リサイズ
  // ===========================================================================
  {
    let duration      = 150; // リサイズ間隔
    let listener      = eventListener;
    let small         = false;
    let large         = false;
    let timer         = null;
    let breakPoint    = marugoto.breakPoint;
    let firstBreak    = true;
    let resizeHandler = (e)=>{
      let width  = win.innerWidth;
      let height = win.innerHeight;
      for(let key in listener.resize) listener.resize[key](e);

      if(width < breakPoint && !small){
        small             = true;
        large             = false;
        state.displaySize = 'small';
        if(firstBreak){
          for(let key in listener.firstBreakBefore) listener.firstBreakBefore[key](e, state.displaySize);
        }
        for(let key in listener.break) listener.break[key](e, state.displaySize);
        if(firstBreak){
          firstBreak = false;
          for(let key in listener.firstBreakAfter) listener.firstBreakAfter[key](e, state.displaySize);
        }
        $win.trigger('scroll', true);
      }
      if(width >= breakPoint && !large){
        small             = false;
        large             = true;
        state.displaySize = 'large';
        if(firstBreak){
          for(let key in listener.firstBreakBefore) listener.firstBreakBefore[key](e, state.displaySize);
        }
        for(let key in listener.break) listener.break[key](e, state.displaySize);
        if(firstBreak){
          firstBreak = false;
          for(let key in listener.firstBreakAfter) listener.firstBreakAfter[key](e, state.displaySize);
        }
        $win.trigger('scroll', true);
      }
    };
    $win.on('resize', (e)=>{
      if(timer) clearTimeout(timer);
      timer = setTimeout(()=> resizeHandler(e), duration);
    });
  }
  // ===========================================================================
  // スクロール
  // ===========================================================================
  {
    let duration               = 150;           // スクロール間隔
    let nativeScrollCheckStart = 150;           // ネイティブスクロール確認開始時間
    let listener               = eventListener;
    let timer                  = null;
    let nativeScrollCheck      = false;
    let scrollHandler = (e)=>{
      state.scrollTop = $win.scrollTop();
      for(let key in listener.scroll) listener.scroll[key](e, state.scrollTop);
    }
    $win.on('scroll', (e, toJS = false)=>{
      if(!toJS && nativeScrollCheck && !state.nativeScroll){
        state.nativeScroll = true;
      }
      if(timer) clearTimeout(timer);
      timer = setTimeout((e)=>{
        scrollHandler(e);
      }, duration);
    });
    setTimeout(()=>{
      nativeScrollCheck = true;
    }, nativeScrollCheckStart);
  }
  // ===========================================================================
  // ウィンドウフォーカス/ブラー
  // ===========================================================================
  {
    let listener = eventListener;
    $win.on('focus', function(){
      for(let key in listener.toggleFocus) listener.toggleFocus[key]('focus');
    });
    $win.on('blur', function(){
      for(let key in listener.toggleFocus) listener.toggleFocus[key]('blur');
    });
    $win.focus();
  }
  // ###########################################################################
  //
  // 全ページ共通の処理
  //
  // ###########################################################################
  // クエリ文字列を取得 / デバッグ状態かどうかの設定
  // デバックモードか否かは変更できないように、definePropertyで定義
  // PHPで定義している定数DEBUGは利用しない
  // ===========================================================================
  {
    let arr        = [];
    let q          = {};
    let url        = window.location.search;
    let debug      = false;
    let forceDebug = $body.data('secret-param1') === 'm6hg4' ? true : false;
    if(!url){
      state.queryString = false;
    }else{

      arr = url.slice(1).split('&');
      arr = arr.forEach((val)=>{
        val = $('<span>').text(val).text();
        let valArr = val.split('=');
        q[valArr[0]] = valArr[1];
      });
      state.queryString = q;
    }

    if(q['marugotoDebug'] && typeof q['marugotoDebug'] === 'string' && (q['marugotoDebug'] === '1' || q['marugotoDebug']=== 'true')){
      debug = true;
    }
    if(forceDebug) debug = true;
    Object.defineProperty(state, 'debug', {
      'value'        : debug,
      'configurable' : false,
      'enumerable'   : true
    });
  }
  // ===========================================================================
  // 画面幅によってコンテンツを変える
  // ===========================================================================
  eventListener.break.changeCnt = (e, size)=>{
    if(size === 'small'){
      $('*[data-small-only]').css('display', '');
      $('*[data-large-only]').css('display', 'none');
      let $imgs = $('img[data-small-src]');
      for(let i = 0, l = $imgs.length; i < l; i++){
        let $img = $($imgs[i]);
        $img.attr('src', $img.attr('data-small-src'));
      }
    }
    if(size === 'large'){
      $('*[data-small-only]').css('display', 'none');
      $('*[data-large-only]').css('display', '');
      let $imgs = $('img[data-large-src]');
      for(let i = 0, l = $imgs.length; i < l; i++){
        let $img = $($imgs[i]);
        $img.attr('src', $img.attr('data-large-src'));
      }
    }
  };
  // ===========================================================================
  // マウスオーバーで画像切り替え
  // ===========================================================================
  $('img[data-mouse-over]').on({
    'mouseover' : function(){
      if(state.displaySize === 'small') return false;
      let $this = $(this);
      let reg   = /--off\.(gif|jpg|jpeg|png)$/;
      let $path = $this.attr('src');
      if(reg.test($path)) $path = $path.replace(reg, '--on.$1');
      $this.attr('src', $path);
    },
    'mouseout' : function(){
      if(state.displaySize === 'small') return false;
      let $this = $(this);
      let reg   = /--on\.(gif|jpg|jpeg|png)$/;
      let $path = $this.attr('src');
      if(reg.test($path)) $path = $path.replace(reg, '--off.$1');
      $this.attr('src', $path);
    }
  });
  $('a[data-mouse-over]').on({
    'mouseover' : function(){
      if(state.displaySize === 'small') return false;
      let $this = $(this);
      let $img  = $('img[data-mouse-over-target]', $this);
      let reg   = /--off\.(gif|jpg|jpeg|png)$/;
      let $path = $img.attr('src');
      if(reg.test($path)) $path = $path.replace(reg, '--on.$1');
      $img.attr('src', $path);
    },
    'mouseout' : function(){
      if(state.displaySize === 'small') return false;
      let $this = $(this);
      let $img  = $('img[data-mouse-over-target]', $this);
      let reg   = /--on\.(gif|jpg|jpeg|png)$/;
      let $path = $img.attr('src');
      if(reg.test($path)) $path = $path.replace(reg, '--off.$1');
      $img.attr('src', $path);
    }
  });
  // ===========================================================================
  // 言語メニュー
  // ===========================================================================
  {
    let $select = $('.language-select');
    let mode    = 'close';
    $select.on({
      'click' : function(e, force = false){
        $this = $(e.currentTarget);
        if($this.hasClass('open')){
          $this.removeClass('open').addClass('close');
        }else{
          $this.removeClass('close').addClass('open');
        }
      }
    });
  }
  // ===========================================================================
  // Smartphone drawer
  // ===========================================================================
  {
    let $header    = $('.f__header-content');
    let $wrap      = $('.m__sp-drawer');
    let $bg        = $('.bg', $wrap);
    let $menu      = $('.menu', $wrap);
    let $title     = $('.title', $wrap);
    let $openTitle = $('.open-title', $wrap);
    let openPos    = 0;
    let h          = 0;
    let duration   = 1075;
    let animate    = false;
    let setPos     = ()=>{
      openPos = 0;
      h       = $menu.height();
    }
    // -------------------------------------------------------------------------
    // タイトルクリック
    // -------------------------------------------------------------------------
    $title.on('click', ()=>{
      if(animate) return false;
      setPos();
      eventListener.resize.spDrawer();
      $wrap.addClass('open').removeClass('close');
      $('.bg', $wrap).css('opacity', 0);
      animate = true;
      $menu.css('top', h * -1).velocity({
        'top' : openPos
      },{
        'duration' : duration,
        'easing'   : 'easeOutQuint',
        'complete' : ()=>{
          $('.bg', $wrap).css('opacity', 1);
          animate = false;
          $win.trigger('resize');
        }
      });
    });
    // -------------------------------------------------------------------------
    // クローズクリック
    // -------------------------------------------------------------------------
    let closeHandler = ()=>{
      if(animate) return false;
      setPos();
      eventListener.resize.spDrawer();
      animate = true;
      $menu.velocity({
        'top' : h * -1
      },{
        'duration' : duration,
        'easing'   : 'easeInQuart',
        'complete' : ()=>{
          animate = false;
          $('.bg', $wrap).css('opacity', 1);
          $wrap.addClass('close').removeClass('open');
          $win.trigger('resize');
        }
      });
    }
    $('i', $openTitle).on('click', closeHandler);
    $('> .close', $menu).on('click', closeHandler);
    // -------------------------------------------------------------------------
    // リサイズ時
    // -------------------------------------------------------------------------
    eventListener.resize.spDrawer = (e, size)=>{
      if(size === 'large') return false;
      setPos();
      if(!animate && $wrap.hasClass('open')){
        $menu.css('top', openPos);
      }
    };
    // -------------------------------------------------------------------------
    // 初回の処理
    // -------------------------------------------------------------------------
    setPos();
    openPos = $title.offset().top - $title.height();
    h       = $win.height();
    if($wrap.hasClass('open')){
      $menu.css('top', openPos);
    }else{
      $menu.css('top', h * -1);
    }
  }
  // ===========================================================================
  // PC drawer
  // ===========================================================================
  {
    let $openBtn       = $('.m__pc-drawer--btn');
    let $drawer        = $('.m__pc-drawer');
    let btnDuration    = 250;
    let drawerDuration = 250;
    let animate        = false;
    let $closeBtn      = $('.m__pc-drawer h1 i');
    // -------------------------------------------------------------------------
    // オープンボタンイベント
    // -------------------------------------------------------------------------
    $openBtn.on({
      'mouseover' : function(){
        let $this = $(this);
        animate = true;
        $this.velocity('stop').velocity({
            'left' : '-10px'
          },{
            'duration' : btnDuration
        });
      },
      'mouseout' : function(){
        let $this = $(this);
        $this.velocity('stop').velocity({
          'left' : 0
          },{
            'duration' : btnDuration
        });
      },
      'click' : function(){
        $drawer.removeClass('close').addClass('open');
        $drawer.velocity('stop').velocity({
            'left' : 0
          },{
            'duration' : drawerDuration
        });
      }
    });
    // -------------------------------------------------------------------------
    // クローズボタンイベント
    // -------------------------------------------------------------------------
    $closeBtn.on('click', function(){
      let $this = $(this);
      $drawer.velocity('stop').velocity({
          'left' : '-415px'
        },{
          'duration' : btnDuration,
          'complete' : ()=>{
            animate = false;
            $drawer.removeClass('open').addClass('close');
          }
      });
    });
  }
  // ===========================================================================
  // モジュール：topic-list
  // ===========================================================================
  {
    let $topicList = $('.m__topic-list');
    let duration   = 500;
    $('.header', $topicList).on('click', (e)=>{
      $this = $(e.currentTarget);
      $parent = $this.parents('.m__topic-list');
      $cnt    = $parent.children('.cnt');
      if($parent.attr('data-state') === 'close'){
        $parent.attr('data-state', 'open');
      }
      else if($parent.attr('data-state') === 'open'){
        $parent.attr('data-state', 'close');
      }
      $cnt.stop().slideToggle(duration);
    });
    let vars = state.queryString;
    if(vars.topic && vars.topic.match(/^[0-9]{1}$/)){
      $(`.m__topic-list[data-topic="${vars.topic}"] .header`).trigger('click');
      $('html,body').scrollTop($(`#topic-list-${vars.topic}`).offset().top);
    }
  }
  // ===========================================================================
  // モジュール：kanji -> animation(スライド)
  // ===========================================================================
  {
    let kanjiAnimationInit = (animationSelector)=>{
      let list        = {};
      let largeConf   = {
        'responsive' : false,
        'touchEnabled' : false,
        'pager'        : false,
        'controls'     : false,
        'auto'         : false,
        'useCSS'       : false,
        'mode'         : 'fade',
        'pause'        : 2000,
        'speed'        : 500,
        'infiniteLoop' : false,
        'onSlideAfter' : (_slider, _old, _new)=>{
          let id   = $(_slider.context).attr('data-id');
          let data = list[id];
          if(data.itemNum - 1 === _new) data.$root.removeClass('play');
        }
      }
      let smallConf = {
        'responsive'   : true,
        'touchEnabled' : false,
        'pager'        : false,
        'controls'     : false,
        'auto'         : false,
        'useCSS'       : false,
        'mode'         : 'fade',
        'pause'        : 2000,
        'speed'        : 500,
        'infiniteLoop' : false,
        'onSlideAfter' : (_slider, _old, _new)=>{
          let id   = $(_slider.context).attr('data-id');
          let data = list[id];
          if(data.itemNum - 1 === _new) data.$root.removeClass('play');
        }
      }
      let $animation        = $(animationSelector);
      let $sliders          = $('.video ul', $animation);
      let $btns             = $('> .ctrl i', $animation);
      for(let i = 0, l = $btns.length; i < l; i++){
        let $btn = $($btns[i]);
        let id   = `slider-${i}`;
        $btn.attr('data-id', id);
        list[id] = {
          '$btn'    : $btn,
          'slider'  : null,
          '$root'   : $btn.parents('.animation'),
          '$slider' : $('.video ul', $btn.parents('.animation'))
        };
        list[id].itemNum = $('li', list[id].$slider).length;
      }
      // -----------------------------------------------------------------------
      // ボタンクリック
      // -----------------------------------------------------------------------
      $btns.on('click', function(e){
        let $this = $(this);
        let id    = $(this).attr('data-id');
        let data  = list[id];
        data.$root.addClass('play');
        data.slider.goToSlide(0);
        data.slider.stopAuto();
        data.slider.startAuto();
      });
      // -----------------------------------------------------------------------
      //
      // -----------------------------------------------------------------------
      eventListener.break.kanjiAnimation = (e, size)=>{
        for(let key in list){
          if(list[key].slider) list[key].slider.destroySlider();
          if(size === 'large'){
            list[key].slider    = list[key].$slider.bxSlider(largeConf);
            list[key].slider.id = key;
          }else{
            list[key].slider    = list[key].$slider.bxSlider(smallConf);
            list[key].slider.id = key;
          }
        }
      };
    };
    let animationSelector = '.m__kanji .animation';
    // console.log($(animationSelector).length);
    if($(animationSelector).length) kanjiAnimationInit(animationSelector);
  }
  // ===========================================================================
  // モジュール：kanji -> video-animation(動画)
  // ===========================================================================
  {
    let list       = {};
    let $animation = $(animationSelector);
    let btns       = {};
    let activeKey  = null;
    // -------------------------------------------------------------------------
    // movie play
    // -------------------------------------------------------------------------
    let play = (key)=>{
      let $root  = list[key].$root;
      let $video = list[key].$video;
      let $btn   = list[key].$btn;
      $root.addClass('play');
      $video[0].play();
    };
    // -------------------------------------------------------------------------
    // reset state (ex. pause, ended..)
    // -------------------------------------------------------------------------
    let resetState = (key)=>{
      let $root  = list[key].$root;
      let $video = list[key].$video;
      let $btn   = list[key].$btn;
      $root.removeClass('play');
      $video[0].currentTime = 0;
      $video[0].pause();
      activeKey = null;
    }
    // -------------------------------------------------------------------------
    // Init
    // -------------------------------------------------------------------------
    let kanjiVideoAnimationInit = (animationSelector)=>{
      $animation = $(animationSelector);
      for(let i = 0, l = $animation.length; i < l; i++){
        let $_root  = $($animation[i]);
        let $_btn   = $_root.find('.ctrl i');
        let $_video = $_root.find('.video');
        let _id     = `video-${i}`;
        $_root.data('id', _id);
        $_btn.data('id', _id);
        $_video.data('id', _id);
        list[_id] = {
          '$btn'   : $_btn,
          '$video' : $_video,
          'state'  : 'pause',
          '$root'  : $_root
        };
      }
      for(let key in list){
        let $btn   = list[key].$btn;
        let $video = list[key].$video;

        // ---------------------------------------------------------------------
        // ボタンクリック
        // ---------------------------------------------------------------------
        $btn.on('click', function(e){
          let $this  = $(this);
          let key    = $this.data('id');
          let $video = list[key].$video;
          let $root  = list[key].$root;

          if(activeKey && activeKey !== key) resetState(activeKey);
          activeKey = key;
          play(activeKey);
        });
        // ---------------------------------------------------------------------
        // 動画クリック
        // ---------------------------------------------------------------------
        $video.on('click', function(e){
          let $this  = $(this);
          let key    = $this.data('id');
          let $btn   = list[key].$btn;
          let $root  = list[key].$root;
          resetState(activeKey);
        });
        // ---------------------------------------------------------------------
        // 動画終了
        // ---------------------------------------------------------------------
        $video.on('ended', function(e){
          resetState(activeKey);
        })
      }
    };
    let animationSelector = '.m__kanji .video-animation';
    if($(animationSelector).length) kanjiVideoAnimationInit(animationSelector);
  }
  // ===========================================================================
  // ルビ ON / OFF
  // ===========================================================================
  {
    let active;
    let $parent = $('.ruby-on-off');
    $('.ruby-on-off input').on('change', (e)=>{
      let active = $('input:checked', $parent);
      let state  = '';
      if(active.val() === 'on'){
        $('body').removeClass('no-ruby');
        state = 'on';
      }else{
        $('body').addClass('no-ruby');
        state = 'off';
      }
      $.cookie('marugoto_page-ruby', state, { expires : 2 });
    });

    let cookieState = $.cookie('marugoto_page-ruby') || 'on';

    $(`.ruby-on-off input[value="${cookieState}"]`).trigger('click');
  }
  // ===========================================================================
  // 音声データの読み込み
  // ===========================================================================
  {
    let $list    = $('*[data-audio]');
    let Audio    = marugoto.class.Audio;
    let count    = 0;
    let audioBox = {};
    for(let i = 0, l = $list.length; i < l; i++){
      let $audio = $($list[i]);
      let url    = $audio.attr('data-audio');
      let stamp  = Math.floor(new Date().getTime() / 1000);
      url = `${url}?stamp=${stamp}`;
      let audio  = new Audio(url, `single-audio-${count}`, false, false, 'metadata');
      count++;
      audio.then((($audio)=>{
        return (id, instance)=>{
          audioBox[id] = instance;
          $audio.attr('data-audio-id', id);
          $audio.attr('data-can-play', 'true');
        }
      })($audio));
    }

    $list.on('click', (e)=>{
      e.preventDefault();
      let $audio = $(e.currentTarget);
      let id     = $audio.attr('data-audio-id');
      if(!id) return false;
      let player = audioBox[id];
      player.replay();
    });
  }
  // ===========================================================================
  // ハッシュの設定
  // ===========================================================================
  {
    if(location.hash){
      state.hash = location.hash;
      eventListener.firstBreakAfter.hash = (e, size)=>{
        setTimeout(()=>{
          if(state.nativeScroll) return false;
          $win.scrollTop($(state.hash).offset().top);
        }, 250);
      }
    }
  }
  // ===========================================================================
  // 練習問題の設定
  // ===========================================================================
  {
    let $target = $('*[data-practice-anchor="root"]');
    if($target.length){
      for(let i = 0, l = $target.length; i < l; i++){
        new marugoto.class.Practice($($target[i]));
      }
    }
  }
  // ===========================================================================
  // モーダルの設定
  // ===========================================================================
  {
    marugoto.modal = new marugoto.class.Modal(marugoto);
    $('*[data-modal-trigger]').on('click', (e)=>{
      e.preventDefault();
      let $this = $(e.currentTarget);
      marugoto.modal.show($this);
    });
  }
  // ===========================================================================
  // practice-permutation-boxの設定
  // ===========================================================================
  {
    let $boxies = $('.d__practice-permutation-box');
    // -------------------------------------------------------------------------
    // サイズチェック
    // -------------------------------------------------------------------------
    let sizeCheck = (size)=>{
      $boxies.removeClass('size-over');
      let $box;
      let boxW;
      let leftW;
      let rightW;
      if(size === 'large'){
        for(let i = 0, l = $boxies.length; i < l; i++){
          $box   = $($boxies[i]);
          boxW   = $box.innerWidth();
          leftW  = $('> .left', $box).innerWidth();
          rightW = $('> .right', $box).innerWidth();
          if(leftW + rightW > boxW) $box.addClass('size-over');
        }
      }
    }
    marugoto.global.practicePermutationBoxSizeCheck = ()=>{
      sizeCheck(marugoto.state.displaySize);
    }
    // -------------------------------------------------------------------------
    // ブレイク時の処理
    // -------------------------------------------------------------------------
    eventListener.break.boxies = (e, size)=>{
      sizeCheck(size);
    }
  }
  // ===========================================================================
  // かんじカードの設定
  // ===========================================================================
  {
    // かんじカードがモーダルに突っ込まれたタイミングでインスタンスを作成
    marugoto.modal.listener.show.kanjiCard = (e)=>{
      new marugoto.class.KanjiCard(e.addCnt);
    }
  }
  // ===========================================================================
  // 特定の要素の上に乗っているとき右クリック禁止
  // ===========================================================================
  {
    // 右クリックした時の処理
    let rightClickHandler = (e)=>{
      return false;
    }
    // マウス操作時の処理
    let overLeaveHander = (e)=>{
      if(e.type === 'mouseover'){
        $(document).on('contextmenu', rightClickHandler);
      }else{
        $(document).off('contextmenu', rightClickHandler);
      }
    }
    // m__video
    $(document).on('mouseover',  '.m__video .over-controller', overLeaveHander);
    $(document).on('mouseleave', '.m__video .over-controller', overLeaveHander);
    $(document).on('mouseover',  '.m__video .container', overLeaveHander);
    $(document).on('mouseleave', '.m__video .container', overLeaveHander);
  }
  // ===========================================================================
  // SNS Share
  // ===========================================================================
  {
    let pageURI         = encodeURIComponent(marugoto.pageData.pageUrl);
    let siteTitle       = encodeURIComponent(marugoto.pageData.siteTitle);
    let pageTitle       = encodeURIComponent(marugoto.pageData.pageTitle);
    let formatPageTitle = encodeURIComponent(`${marugoto.pageData.pageTitle} | `);
    let tweetURI        = 'https://twitter.com/share';
    let facebookURI     = 'https://www.facebook.com/sharer/sharer.php';
    let $tweetShare     = $('.f__header-content li.twitter a, .f__footer-content li.twitter a');
    let $facebookShare  = $('.f__header-content li.facebook a, .f__footer-content li.facebook a');
    // -------------------------------------------------------------------------
    // Twitter
    // -------------------------------------------------------------------------
    $tweetShare.on('click', (e)=>{
      let twitterMsg = encodeURIComponent(marugoto.pageData.twitterMsg);
      e.preventDefault();
      window.open(`${tweetURI}?url=${pageURI}&text=${twitterMsg}`,'tweet', 'width=400, height=300, menubar=no, toolbar=no, scrollbar=no');
    });
    // -------------------------------------------------------------------------
    // Facebook
    // -------------------------------------------------------------------------
    $facebookShare.on('click', (e)=>{
      e.preventDefault();
      window.open(`${facebookURI}?u=${pageURI}`,'tweet', 'width=400, height=300, menubar=no, toolbar=no, scrollbar=no');
    });
  }
  $win.trigger('resize');

})();
